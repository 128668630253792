import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { customizeFieldsEventId } from '../../components/Profile/manifest';
import { memberAccountBMPath } from '../constants';
import { refreshApp } from './public-api';

export function registerGfppEventListeners(
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
) {
  editorSDK.addEventListener('widgetGfppClicked', ({ detail }) => {
    if (detail.id === customizeFieldsEventId) {
      editorSDK?.editor
        .openDashboardPanel('', {
          url: `${memberAccountBMPath}?referralInfo=profile_page_gfpp_cta`,
          closeOtherPanels: false,
        })
        .then(() => refreshApp(flowAPI, editorSDK));
    }
  });
}
