import { GetAppManifestFn, TFunction } from '@wix/yoshi-flow-editor';

import componentJson from './.component.json';

type DePromisify<T> = T extends Promise<infer U> ? U : T;

type AppManifest = DePromisify<ReturnType<GetAppManifestFn>>;

export const customizeFieldsEventId = 'manage-fields-clicked';

export const createAppManifest = (t: TFunction): AppManifest => {
  return {
    controllersStageData: {
      [componentJson.id]: {
        default: {
          gfpp: {
            desktop: {
              mainAction2: {
                actionId: customizeFieldsEventId,
                label: t('app.gfpp.customize-fields'),
              },
              iconButtons: {},
            },
            mobile: {
              iconButtons: {},
            },
          },
        },
      },
    },
  };
};
